import { googlePlacesLoaded } from "~/utils/ErrorChecking";
import GooglePlacesSearch from "~/components/GooglePlacesSearch";
import Input from "~/components/Inputs/Input";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { StyleSheet } from "aphrodite";

/**
 * PropertyAddress
 *
 * Property address screen. Collects users to enter their property address.
 * Uses Google places search.
 *
 * @param {*} props
 * @returns
 */
const PropertyAddress = (props) => {
  const {
    clientType,
    propertyAddressComponents,
    propertyAddress,
    propertyUnitNumber,
    setPropertyAddress,
    setPropertyAddressComponents,
    setPropertyUnitNumber,
    nextScreen,
    prevScreen,
    claimed,
  } = props;
  const screenKey = "PropertyAddress";

  // set states
  const [active, setActive] = useState(() => {
    if (propertyAddress !== undefined && propertyAddress.length >= 1)
      return true;
    return false;
  });
  const [invalid, setInvalid] = useState(false);
  const [errors, setErrors] = useState();
  const [nextButtonTitle, setNextButtonTitle] = useState(() => {
    if (
      propertyAddressComponents.city !== undefined &&
      propertyAddressComponents.state !== undefined
    ) {
      return null;
    }
    if (clientType == "buying") {
      return "Please enter a city";
    }
    return "Please enter a valid address";
  });

  // set context dependent text strings
  const heading =
    clientType == "buying"
      ? "Where are you looking to buy?"
      : "What is your address?";
  const label =
    clientType == "buying" ? "Enter city name" : "Enter your full address";

  const buyingSearchOptions = {
    componentRestrictions: { country: ["us", "ca"] },
  };

  const sellingSearchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["us", "ca"] },
  };

  const styles = StyleSheet.create({
    locationDropdown: {
      zIndex: 20,
    },
  });

  /**
   * errorMessages
   *
   * Sets the error messages for the address field.
   *
   * @returns
   */
  const errorMessages = () => {
    let errorMessages = [];
    if (propertyAddress === undefined || propertyAddress.length <= 0) {
      if (clientType == "buying") {
        errorMessages.push("Please enter a city");
      } else {
        errorMessages.push("Please enter a valid address");
      }
      setNextButtonTitle(errorMessages[0]);
      setErrors(errorMessages);
      return;
    }

    if (
      propertyAddressComponents.city === undefined &&
      propertyAddressComponents.state === undefined
    ) {
      if (clientType == "buying") {
        errorMessages.push(
          `We can't find your city. Please revise it and try again.`
        );
      } else {
        errorMessages.push(
          `We can't find your address. Please revise it and try again.`
        );
      }

      setNextButtonTitle(errorMessages[0]);
      setErrors(errorMessages);
      return;
    }
    setNextButtonTitle(null);
    setErrors([]);
  };

  return (
    <div className="MessageAgentForm__screen" key="address">
      <div className="MessageAgentForm__screen-heading">{heading}</div>
      <div
        className={`MessageAgentForm__address ${
          clientType === "selling" || clientType === "buying and selling"
            ? "--unit"
            : ""
        }`}
      >
        <div>
          <div
            className={`MessageAgentForm__address-input ${
              active ? "--active" : ""
            } ${invalid ? "--invalid" : ""}`}
            onFocus={(e) => {
              setActive(true);
              const overFlowEl = e.target.closest(".MessageAgentForm");
              const overFlowElTwo = e.target.closest(
                ".message-agent-modal__body"
              );
              if (overFlowEl != undefined) {
                overFlowEl.classList.add("--overflow");
              }
              if (overFlowElTwo != undefined) {
                overFlowElTwo.classList.add("--overflow");
              }
              if (
                propertyAddressComponents.city !== undefined ||
                propertyAddressComponents.state !== undefined
              ) {
                setInvalid(false);
              }
            }}
            onBlur={(e) => {
              if (
                propertyAddress === undefined ||
                propertyAddress.length <= 0
              ) {
                setActive(false);
                if (
                  propertyAddressComponents.city === undefined &&
                  propertyAddressComponents.state === undefined
                ) {
                  setInvalid(true);
                }
              }
              errorMessages();
            }}
          >
            {googlePlacesLoaded() && (
              <GooglePlacesSearch
                locationDropdownStyle={styles.locationDropdown}
                submitForm={() => {}}
                handleChange={(value) => {
                  setPropertyAddress(value);
                }}
                required={true}
                options={
                  clientType === "buying"
                    ? buyingSearchOptions
                    : sellingSearchOptions
                }
                searchValue={propertyAddress ? propertyAddress : ""}
                setAddressComponents={(value) => {
                  setPropertyAddressComponents(value);
                  setErrors([]);
                  setInvalid(false);
                  setNextButtonTitle(null);
                }}
              />
            )}
            <label htmlFor="google_places_search">{label}</label>
          </div>
          {errors && errors.length > 0 ? (
            <ul className="Input__errors">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          ) : null}
        </div>
        {clientType === "selling" || clientType === "buying and selling" ? (
          <Input
            name="unit-number"
            onChange={(value) => {
              setPropertyUnitNumber(value);
            }}
            value={propertyUnitNumber || ""}
            label="Unit #"
            maxlength="25"
          />
        ) : (
          ""
        )}
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={(e) => {
            const overFlowEl = e.target.closest(".MessageAgentForm");
            const overFlowElTwo = e.target.closest(
              ".message-agent-modal__body"
            );
            if (overFlowEl != undefined) {
              overFlowEl.classList.remove("--overflow");
            }
            if (overFlowElTwo != undefined) {
              overFlowElTwo.classList.remove("--overflow");
            }
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
        <button
          type="button"
          onClick={(e) => {
            const overFlowEl = e.target.closest(".MessageAgentForm");
            const overFlowElTwo = e.target.closest(
              ".message-agent-modal__body"
            );
            if (overFlowEl != undefined) {
              overFlowEl.classList.remove("--overflow");
            }
            if (overFlowElTwo != undefined) {
              overFlowElTwo.classList.remove("--overflow");
            }
            nextScreen({ key: screenKey });
          }}
          disabled={
            propertyAddressComponents.city === undefined &&
            propertyAddressComponents.state === undefined
          }
          title={nextButtonTitle}
        >
          Next
        </button>
      </div>
    </div>
  );
};

PropertyAddress.propTypes = {
  clientType: PropTypes.string,
  propertyAddress: PropTypes.string,
  propertyAddressComponents: PropTypes.object,
  propertyUnitNumber: PropTypes.string,
  setPropertyAddress: PropTypes.func.isRequired,
  setPropertyAddressComponents: PropTypes.func.isRequired,
  setPropertyUnitNumber: PropTypes.func.isRequired,
  nextScreen: () => {},
  prevScreen: () => {},
  claimed: PropTypes.bool.isRequired,
};

PropertyAddress.defaultProps = {
  clientType: undefined,
  propertyAddress: undefined,
  propertyAddressComponents: {},
  propertyUnitNumber: undefined,
  setPropertyAddress: () => {},
  setPropertyAddressComponents: () => {},
  setPropertyUnitNumber: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
  claimed: false,
};

export default PropertyAddress;
