import PropTypes from "prop-types";
import React from "react";
import { ViewAgents } from "../ViewAgents";
import { genericFunnels } from "~/config/funnel";

/**
 * ClientType
 *
 * Client type screen. Collects users type.
 *
 * @param {*} props
 * @returns
 */
const ClientType = (props) => {
  const {
    claimed,
    clientType,
    setClientType,
    nextScreen,
    prevScreen,
    funnel,
    leadUUID,
    messageHeader,
  } = props;
  const screenKey = "ClientType";

  const handleClick = (e) => {
    setClientType(e.target.value);
    setTimeout(() => {
      nextScreen({ key: screenKey, value: e.target.value });
    }, 200);
  };

  return (
    <div
      className={`MessageAgentForm__screen --delay ${
        claimed ? "--alt-animation" : ""
      }`}
    >
      <div className="MessageAgentForm__screen-heading">
        {messageHeader
          ? messageHeader
          : genericFunnels.includes(funnel)
          ? "Are you buying or selling?"
          : "What is your message about?"}
      </div>
      <div className="MessageAgentForm__clientType">
        <label>
          <input
            name="need"
            type="radio"
            value="buying"
            onClick={(e) => {
              handleClick(e);
            }}
            defaultChecked={clientType === "buying"}
          />
          <div>
            <span>
              <span>
                <span className="--inner">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M12.4688 23.0625C12.4688 23.5803 12.0491 24 11.5313 24H0.937501C0.419678 24 0 23.5803 0 23.0625V10.1563C0 9.878 0.123596 9.61414 0.337281 9.43617L11.3998 0.217391C11.7475 -0.0724637 12.2525 -0.0724637 12.6002 0.217391L23.6627 9.43617C24.0605 9.76759 24.1141 10.3586 23.7827 10.7565C23.4513 11.1542 22.86 11.2079 22.4623 10.8765L12 2.15794L1.875 10.5954V22.125H11.5313C12.0491 22.125 12.4688 22.5446 12.4688 23.0625ZM22.516 20.5263C21.4873 21.7664 19.9512 22.9019 17.9502 23.9013C17.6864 24.0329 17.3762 24.0329 17.1123 23.9013C15.1113 22.9019 13.5753 21.7664 12.5468 20.5263C9.4933 16.8453 11.6195 12.7828 14.7656 12.7828C16.0194 12.7828 16.9417 13.296 17.5313 13.7778C18.1209 13.296 19.0432 12.7828 20.2969 12.7828C23.4491 12.7828 25.5617 16.8543 22.516 20.5263ZM20.2969 14.6578C19 14.6578 18.3192 15.6301 18.3126 15.6398C17.9233 16.227 17.1024 16.1911 16.7523 15.6436C16.6948 15.5629 16.0217 14.6578 14.7656 14.6578C12.7449 14.6578 11.0092 18.552 17.5313 22.0083C24.0533 18.552 22.318 14.6578 20.2969 14.6578Z"
                      fill="#272727"
                    />
                  </svg>
                  <em>I'm Buying a Home</em>
                  <span className="MessageAgentForm__clientType-subtext">
                    Buy the right home at the right price
                  </span>
                </span>
              </span>
            </span>
          </div>
        </label>
        <label>
          <input
            name="need"
            type="radio"
            value="selling"
            onClick={(e) => {
              handleClick(e);
            }}
            defaultChecked={clientType === "selling"}
          />
          <div>
            <span>
              <span>
                <span className="--inner">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M24 0.872695C24 0.326166 23.544 -0.000612711 23.0705 8.62523e-07C22.3126 0.000990237 20.0914 0.00361583 12.5252 0.00504045C7.31799 0.00603184 4.80603 0.00426669 3.61728 0.00262647C2.85628 0.00157664 2.23761 0.60772 2.23761 1.36278L2.23168 22.1629H0.9254C0.414551 22.1629 0.000761952 22.5721 1.0419e-06 23.077C-0.000756812 23.5822 0.412015 23.9941 0.923505 23.9964C1.31234 23.998 2.11601 24 4.25251 24C6.39466 24 7.1463 23.998 7.50491 23.9963C8.02595 23.9939 8.42822 23.5599 8.42822 23.0655C8.42822 22.5497 8.00365 22.1629 7.50297 22.1629H4.03706L4.04298 1.83632H5.92316L7.25249 1.84017H9.3938V4.59152H6.92882C6.2422 4.59152 5.67353 5.08896 5.57061 5.73963C5.55963 5.80899 5.554 5.8799 5.55404 5.95188C5.555 7.45058 5.5579 8.94924 5.56079 10.4476V10.4487V10.4498L5.56407 12.2027C5.56566 13.1175 5.56696 14.0322 5.56755 14.9469C5.56803 15.6978 6.1835 16.3069 6.94302 16.3069H22.5411C23.3018 16.3069 23.9136 15.6966 23.9136 14.9467V5.95165C23.9136 5.20048 23.298 4.59152 22.5385 4.59152H20.0939V1.84017H23.0715C23.6212 1.84017 24 1.34133 24 0.872695ZM11.1992 4.59152V1.84017H18.2886V4.59152H11.1992ZM7.34998 14.4697V6.42865H22.1378V14.4697H7.34998Z"
                      fill="#272727"
                    />
                  </svg>
                  <em>I'm Selling My Home</em>
                  <span className="MessageAgentForm__clientType-subtext">
                    Sell your home fast and for more
                  </span>
                </span>
              </span>
            </span>
          </div>
        </label>
        <label>
          <input
            name="need"
            type="radio"
            value="buying and selling"
            onClick={(e) => {
              handleClick(e);
            }}
            defaultChecked={clientType === "buying and selling"}
          />
          <div>
            <span>
              <span>
                <span className="--inner">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.6">
                      <path
                        d="M12.4688 23.0625C12.4688 23.5803 12.0491 24 11.5313 24H0.937501C0.419678 24 0 23.5803 0 23.0625V10.1563C0 9.878 0.123596 9.61415 0.337281 9.43617L11.3998 0.217391C11.7475 -0.0724637 12.2525 -0.0724637 12.6002 0.217391L23.6627 9.43617C24.0604 9.76759 24.1141 10.3586 23.7827 10.7565C23.4513 11.1542 22.86 11.2079 22.4623 10.8765L12 2.15794L1.875 10.5954V22.125H11.5313C12.0491 22.125 12.4688 22.5446 12.4688 23.0625Z"
                        fill="#272727"
                      />
                      <path
                        d="M18.0003 15.3594C17.074 15.3594 16.3203 16.113 16.3203 17.0394C16.3203 17.9657 17.074 18.7194 18.0003 18.7194C18.9267 18.7194 19.6803 17.9657 19.6803 17.0394C19.6803 16.113 18.9267 15.3594 18.0003 15.3594ZM18.0003 18.012C17.464 18.012 17.0277 17.5757 17.0277 17.0394C17.0277 16.5031 17.464 16.0667 18.0003 16.0667C18.5366 16.0667 18.9729 16.5031 18.9729 17.0394C18.9729 17.5757 18.5366 18.012 18.0003 18.012Z"
                        fill="#272727"
                        stroke="#272727"
                        strokeWidth="0.48"
                      />
                      <path
                        d="M18.0098 12.4805C15.4897 12.4805 13.4395 14.5307 13.4395 17.0508V17.1771C13.4395 18.4516 14.1702 19.9368 15.6113 21.5914C16.6561 22.7909 17.6862 23.6273 17.7296 23.6623L18.0098 23.8887L18.29 23.6623C18.3333 23.6273 19.3635 22.7909 20.4082 21.5914C21.8493 19.9368 22.5801 18.4517 22.5801 17.1771V17.0508C22.5801 14.5307 20.5298 12.4805 18.0098 12.4805ZM21.6883 17.1771C21.6883 19.331 18.9131 21.9383 18.0098 22.7298C17.1062 21.9381 14.3312 19.3308 14.3312 17.1771V17.0508C14.3312 15.0225 15.9814 13.3722 18.0098 13.3722C20.0381 13.3722 21.6883 15.0225 21.6883 17.0508V17.1771Z"
                        fill="#272727"
                        stroke="#272727"
                        strokeWidth="0.96"
                      />
                    </g>
                  </svg>
                  <em>I'm Buying & Selling</em>
                  <span className="MessageAgentForm__clientType-subtext">
                    Our agents make it easy to do both
                  </span>
                </span>
              </span>
            </span>
          </div>
        </label>
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={(e) => {
            prevScreen({ key: screenKey, value: e });
          }}
        >
          Back
        </button>
        <ViewAgents leadUUID={leadUUID} />
      </div>
    </div>
  );
};

ClientType.propTypes = {
  claimed: PropTypes.bool,
  clientType: PropTypes.string,
  setClientType: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

ClientType.defaultProps = {
  claimed: false,
  clientType: undefined,
  setClientType: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
};

export default ClientType;
