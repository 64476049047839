import LightningFetch from "~/utils/Fetch/Lightning";
import { progressivePolling } from "~/utils/Poll";

const checkLightning = (uuid) => {
  return new Promise((resolve, reject) => {
    LightningFetch(uuid).then((resp) => {
      if (resp.status == 200) resolve(resp);
      reject(resp);
    });
  });
};

export const maybe2fa = async (uuid) => {
  const check = () => {
    return checkLightning(uuid);
  };
  let do2FA = false;
  let resp = {};
  let twoFA = await progressivePolling(check, 1000);

  if (!Object.values(twoFA.resp.funnel_problems).every((v) => v === false)) {
    resp = twoFA.resp.funnel_problems;
    do2FA = true;
  }

  return [do2FA, resp];
};
